@import url("https://fonts.cdnfonts.com/css/luthon-southard-script");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

* {
  box-sizing: border-box; 
}

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  width: 100%;
}

header {
  position: fixed; 
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999; 
}

.slick-prev,
.slick-next {
  border-radius: 50%; 
  width: 40px; 
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10; 
}

.slick-prev:before,
.slick-next:before {
  margin-left: 15px ;
  margin-right: 15px;
  font-size: 20px;
  color:transparent; 
  opacity: 1; 
}

.slick-slide {
  margin: 0 12px;
  width: auto;
  box-shadow: none !important; 
  border: none !important; 
}

.slick-track {
  display: flex;
  justify-content: flex-start;
}

.slick-dots {
  bottom: 10px; 
}

.slick-dots li button:before {
  color: #000; 
}

.slick-prev,
.slick-next {
  box-shadow: none !important;
  outline: none !important;
}

.slick-slide > div {
  box-shadow: none !important; 
}

.slick-track {
  box-shadow: none !important;
}

.slick-list {
  box-shadow: none !important; 
}

.slick-slide img {
  box-shadow: none !important;
  border: none !important;
}

.relative.bg-white.rounded-lg {
  box-shadow: none !important;
}

.slick-slide > div {
  box-shadow: none !important;
}

.slick-slide * {
  box-shadow: none !important;
}

.slick-slide:hover {
  box-shadow: none !important; 
}

.marquee-container {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.marquee {
  display: flex;
  white-space: nowrap;
  animation: marquee 20s linear infinite; 
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%); 
  }
}

.marquee-image {
  height: 64px; 
  margin-right: 20px; 
  flex-shrink: 0;  
}

.marquee {
  display: flex;
  align-items: center;
}

.marquee-container {
  display: flex;
  justify-content: flex-start;
}

.marquee img {
  flex-shrink: 0;
}

.slick-slider {
  overflow: hidden !important;
}

.slick-list {
  padding: 0px !important;
  margin: 0px auto !important;
  overflow: hidden !important;
}

.slick-track {
  display: flex !important;
  gap: 0px !important;
}

.slick-slide {
  float: none !important;
}

.slick-slide > div {
  margin: 0px !important;
  padding: 0px !important;
}

/* Mobile specific adjustments */
@media (max-width: 768px) {
  /* Reduce the margins on smaller screens */
  .slick-slide {
    margin: 0 5px; /* Adjusted margin to fit on the screen */
  }

  /* Add padding to left and right of the slick list */
  .slick-list {
    padding: 0 4 px !important; /* Padding to avoid content touching edges */
  }

  /* Ensure the shadow stays inside the viewport and doesn't overflow */
  .slick-slide > div {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1) !important; /* Shadow stays inside */
  }

  /* Allow the shadow on the left and right sides without overflowing */
  .slick-list {
    overflow: visible !important;
  }

  /* Fix alignment of the carousel */
  .slick-track {
    display: flex !important;
    justify-content: center !important; /* Center align the items */
    padding: 0 !important; 
  }
}
